import React from "react";

const Avatar = ({firstName, lastName, size, team, telegramPhotoUrl}) => {
  const borderColor = team === "red" ? "#EF2424" : "#000AFF";
  const avatarUrl = telegramPhotoUrl
    ? telegramPhotoUrl
    : `https://avatar.iran.liara.run/username?username=${firstName}+${lastName}`;

  return (
    <div className="user-avatar" style={{width: size, height: size, border: `2px solid ${borderColor}`}}>
      <img src={avatarUrl} alt="User"/>
    </div>

  );
};

export default Avatar;
