import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../constants";
import redTeam from "../assets/trump-photo.png";
import blueTeam from "../assets/harris-photo.png";
import ButtonLarge from "../components/ButtonLarge";
import VoteTweet from "../components/VoteTweet";
import VoteModal from "../components/VoteModal";
import {formatNumberWithCommas} from "../helpers";

const VOTE_POINTS = 10000;

const SelectParty = () => {
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const initData = window.Telegram?.WebApp?.initData;

  const submitTeamHandler = async (e) => {
    e.preventDefault();

    if (selectedTeam) {
      try {
        const response = await axios.request({
          method: 'POST',
          baseURL: API_URL,
          url: '/telegram-users/select-team',
          data: { team: selectedTeam },
          headers: {
            initdata: initData,
            'ngrok-skip-browser-warning': '69420'
          },
        });
        if (response.data.success) {
          setIsModalOpen(true);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  const selectTeam = (team) => {
    setSelectedTeam(team);
  };

  const resetSelection = (e) => {
    if (e.target.closest('.team-card')) return;
    setSelectedTeam(null);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate('/tabs');
  };

  return (
    <div className="container select-party-container"
         onClick={resetSelection}>
      <div className="select-party-inner-wrap">
        <div className="select-party">
          <div className="select-party-title-wrap">
            <p>2024 US PRESIDENTIAL ELECTION BALLOT</p>
          </div>
          <div className="select-party-content">
            <h1 className="title">CAST MY VOTE</h1>
            <h2 className="subtitle">EARN</h2>
            <h3 data-content={`+$${formatNumberWithCommas(VOTE_POINTS)} Pts`}>
              +${formatNumberWithCommas(VOTE_POINTS)} Pts
            </h3>
            <div className="team-card-wrap">
              <button
                className={`team-card ${selectedTeam === 'red' ? 'selected' : ''} ${selectedTeam && selectedTeam !== 'red' ? 'zoom-out' : ''}`}
                onClick={() => selectTeam('red')}
              >
                <div className="image-container">
                  <img src={redTeam} alt="Red Team"/>
                </div>
                <span className="team-card-btn">Trump</span>
              </button>
              <button
                className={`team-card blue ${selectedTeam === 'blue' ? 'selected' : ''} ${selectedTeam && selectedTeam !== 'blue' ? 'zoom-out' : ''}`}
                onClick={() => selectTeam('blue')}
              >
                <div className="image-container">
                  <img src={blueTeam} alt="Blue Team"/>
                </div>
                <span className="team-card-btn">Harris</span>
              </button>
            </div>
          </div>
          <div className="select-party-content-bottom">
            <p className="select-party-prize-title">Grand Prize</p>
            <p className="select-party-prize-points">500,500,000 Points</p>
          </div>
        </div>
        <ButtonLarge
          onClick={submitTeamHandler}
          disabled={!selectedTeam}
        >
          Continue
        </ButtonLarge>
      </div>
      <VoteModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      >
        <VoteTweet />
      </VoteModal>
    </div>
  );
};

export default SelectParty;
