import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_URL, telegramLink, twitterLink} from "../constants";
import {getExtraInformation, getTwitterVote} from "../services";
import {formatNumberWithCommas, tweet} from "../helpers";
import ButtonLarge from "../components/ButtonLarge";
import Preloader from "../components/Preloader";
import earnPointsData from "../assets/earn-points.json";

const EarnPointsScreen = () => {
  const [earnPoints, setEarnPoints] = useState([]);
  const [isUserSubscribedOnTelegram, setIsUserSubscribedOnTelegram] = useState(false);
  const [isUserSharedOnX, setIsUserSharedOnX] = useState(null);
  const [isUserSubscribedOnX, setIsUserSubscribedOnX] = useState(false);
  const [loading, setLoading] = useState(true);
  const initData = window.Telegram?.WebApp?.initData;

  const gameUrl = process.env.REACT_APP_GAME_URL;
  const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
  const text = "Join the Whack’em fun and claim your free 10,000 points! Sign up now to get a head start on the Whack’em Airdrop and enjoy the game!";
  const encodedText = encodeURIComponent(text);
  const fullUrl = `${gameUrl}?startapp=ref_${userId}`;

  useEffect(() => {
    (async () => {
      const extraInformationData = await getExtraInformation();
      setIsUserSubscribedOnTelegram(extraInformationData.isUserSubscribedOnTelegram);
      setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
      setIsUserSubscribedOnX(extraInformationData.isUserSubscribedOnX);
      setLoading(false);
    })();

    setEarnPoints(earnPointsData);
  }, []);

  const handleTweetVote = async () => {
    tweet(gameUrl, userId);
    await getTwitterVote();
    const extraInformationData = await getExtraInformation();
    setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
  };

  const handleClaimClick = async (id, platform) => {
    if (platform !== "refer") {
      try {
        const response = await axios.request({
          method: 'POST',
          baseURL: API_URL,
          url: 'telegram-users/generate-social-media-reward',
          data: { platform },
          headers: {
            initdata: initData,
            'ngrok-skip-browser-warning': '69420'
          },
        });
        if (response.data.success) {
          switch(platform) {
            case "telegram":
              window?.Telegram?.WebApp?.openTelegramLink(telegramLink);
              break;
            case "x-vote":
              const extraInformationData = await getExtraInformation();
              setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
              break;
            case "twitter":
              window?.Telegram?.WebApp?.openLink(twitterLink);
              break;
            default:
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };

  if (!earnPoints || loading) {
    return <Preloader />;
  }

  return (
    <div className="earn-points-container">
      <ul>
        {earnPoints.map((item) => (
          <li
            key={item.id}
            className="earn-points-item"
          >
            <div className="earn-points-bar">
              <img src={item.icon} alt="icon"/>
            </div>
            <div className="earn-points-content">
              <h3 className="earn-points-title">{item.title}</h3>
              <span className="earn-points-value" data-content={`+${formatNumberWithCommas(item.points)} Pts`}>
                +{formatNumberWithCommas(item.points)} Pts
              </span>
              {item.platform === 'telegram' && (
                <ButtonLarge
                  onClick={() => handleClaimClick(item.id, item.platform)}
                  additionalClass={isUserSubscribedOnTelegram ? "button-done" : ""}
                >
                  {isUserSubscribedOnTelegram ? 'Done' : item.buttonTitle}
                </ButtonLarge>
              )}

              {item.platform === 'x-vote' && (
                isUserSharedOnX === 'claim' || isUserSharedOnX === 'done' ? (
                  <ButtonLarge
                    onClick={() => handleClaimClick(item.id, item.platform)}
                    additionalClass={isUserSharedOnX === "done" ? "button-done" : ""}
                    disabled={isUserSharedOnX === "null"}
                  >
                    {isUserSharedOnX === 'claim' ? 'Claim' : 'Done'}
                  </ButtonLarge>
                ) : (
                  <ButtonLarge
                    onClick={handleTweetVote}
                    additionalClass={isUserSharedOnX === 'pending' ? 'button-pending' : ''}
                  >
                    {isUserSharedOnX === 'pending' ? 'Pending' : item.buttonTitle}
                  </ButtonLarge>
                )
              )}

              {item.platform === 'twitter' && (
                <ButtonLarge
                  onClick={() => handleClaimClick(item.id, item.platform)}
                  additionalClass={isUserSubscribedOnX ? "button-done" : ""}
                  disabled={isUserSharedOnX === 'pending' || isUserSharedOnX === 'null'}
                >
                  {isUserSubscribedOnX ? 'Done' : item.buttonTitle}
                </ButtonLarge>
              )}

              {item.platform === 'refer' && (
                <a href={`https://t.me/share/url?url=${encodeURIComponent(fullUrl)}&text=${encodedText}`}>
                  <ButtonLarge>Refer Friends</ButtonLarge>
                </a>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EarnPointsScreen;
