import React, { useState } from 'react';
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import {API_URL, telegramLink} from "../constants";
import Footer from '../components/Footer';

const SubscribeScreen = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(false);

    const validateSubscription = () => {
        setErrorMessage(null);
        axios.request({
            method: 'GET',
            baseURL: API_URL,
            url: '/telegram-users/verify-subscription',
            headers: {
                initdata: window.Telegram.WebApp.initData,
                'ngrok-skip-browser-warning': '69420'
            }
        }).catch((error) => {
            setErrorMessage(error);
            console.error("Error fetching telegram data:", error);
            return false;
        }).then(res => {
            
            if (res.data) {
              navigate('/congratulations')
            } else {
              setErrorMessage("You need to subscribe to our channel!");
            }
        });
    };

  return (
    <div className="container">
      <div className="task-cointainer">
        <h3>WHACK’EM Holder</h3>
        <p>Complete tasks and accumulate points even before
        the launch of our game</p>
      </div>
      <div className="subscribe-container">
        <h2>Whack’Em VIP Lounge</h2>
          <div className="white-container">
              <p>To access our VIP Lounge, subscribe to the Whack’em Telegram channel</p>
              <a target='_blank' href={telegramLink} rel="noreferrer">
                  <button className="orange-button">Subscribe</button>
              </a>
              <button className="blue-button" onClick={validateSubscription}>Verify Subscription</button>
              <Link to="/select-party" style={{display: 'block'}}>Select party test</Link>
              <p className="errorMessage">{errorMessage}</p>
          </div>
          <p className="hint">Complete this task to unlock our VIP room
        and access additional reward points</p>
      </div>
      <Footer />
    </div>
  );
};

export default SubscribeScreen;
