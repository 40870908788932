import React, {useEffect, useRef, useState} from "react";
import {getVoteStats, postVoteCats} from "../services";
import {formatNumberWithCommas, parsePercentage} from "../helpers";
import Statistics from "../components/Statistics";
import Preloader from "../components/Preloader";
import redTeam from "../assets/trump-photo.png";
import blueTeam from "../assets/harris-photo.png";

const BetWinScreen = () => {
  const [votedTeam, setVotedTeam] = useState('');
  const [userVote, setUserVote] = useState(null);
  const [redPercentage, setRedPercentage] = useState(0);
  const [bluePercentage, setBluePercentage] = useState(0);
  const [myPurse, setMyPurse] = useState(null);
  const [castedVotes, setCastedVotes] = useState(0);
  const [referrals, setReferrals] = useState(0);
  const [showStatistics, setShowStatistics] = useState(false);
  const [loading, setLoading] = useState(true);
  const isVoteDisabled = votedTeam === '';
  const statisticsRef = useRef(null);

  const fetchAndUpdateData = async () => {
    try {
      const voteStatsData = await getVoteStats();
      setUserVote(voteStatsData.userVote);
      setVotedTeam(voteStatsData.userVote || '');
      setRedPercentage(voteStatsData.redPercentage);
      setBluePercentage(voteStatsData.bluePercentage);
      setMyPurse(voteStatsData.myPurse);
      setCastedVotes(voteStatsData.castedVotes);
      setReferrals(voteStatsData.referrals);
    } catch (error) {
      console.error("Error fetching vote stats:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAndUpdateData();
  }, []);

  useEffect(() => {
    if (showStatistics && statisticsRef.current) {
      statisticsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showStatistics]);

  const handleTeamSelect = (team) => {
    if (userVote === null) {
      setVotedTeam(team);
    }
  };

  const handleVote = async () => {
    try {
      await postVoteCats(votedTeam);
      await fetchAndUpdateData();
      setShowStatistics(true);
    } catch (error) {
      console.error("Error posting vote:", error);
    }
  };

  const handleDetailsClick = () => {
    setShowStatistics(!showStatistics);
  };

  if (loading) {
    return <Preloader />;
  }

  return (
      <div className="bet-win-container">
        <div className="bet-win-inner-wrap">
          <div className="bet-win">
            <div className="select-party-title-wrap">
              {userVote === null ? (
                <p>Vote: us presidential election 2024</p>
              ) : (
                <p>Live Voting Ballot</p>
              )}
            </div>
            <div className="bet-win-content">
            <h1 className="title">Grand Prize</h1>
              <p className="points">500,500,000 Points</p>
              <div className="team-card-wrap">
                <button
                  className={`team-card ${votedTeam === 'red' ? 'red' : ''} ${userVote !== null ? 'no-cursor' : ''}`}
                  onClick={() => handleTeamSelect('red')}
                >
                  <div className="image-container">
                    <img src={redTeam} alt="Red Team"/>
                  </div>
                  <h2>Trump</h2>
                  <span>{parsePercentage(redPercentage)}%</span>
                </button>
                <button
                  className={`team-card ${votedTeam === 'blue' ? 'blue' : ''} ${userVote !== null ? 'no-cursor' : ''}`}
                  onClick={() => handleTeamSelect('blue')}
                >
                  <div className="image-container">
                    <img src={blueTeam} alt="Blue Team"/>
                  </div>
                  <h2>Harris</h2>
                  <span>{parsePercentage(bluePercentage)}%</span>
                </button>
              </div>
              {userVote === null && (
                <button
                  className="bet-win-btn orange"
                  onClick={handleVote}
                  disabled={isVoteDisabled}
                >
                  <span>Vote</span>
                </button>
              )}
              {userVote !== null && (
                <button
                  className="bet-win-btn purple"
                  onClick={handleDetailsClick}
                >
                  <span>
                    {showStatistics ? 'Back' : 'Details'}
                  </span>
                </button>
              )}
            </div>
          </div>
          {userVote !== null && showStatistics && (
            <div ref={statisticsRef}>
              <Statistics
                stats={[
                  { title: "My Purse", value: formatNumberWithCommas(Math.round(myPurse)), icon: "award" },
                  { title: "Casted Votes", value: formatNumberWithCommas(Math.round(castedVotes)), icon: "play" },
                  { title: "Referrals", value: formatNumberWithCommas(Math.round(referrals)), icon: "trend-up" },
                  { title: "Election Day", value: "Nov 20, 2024", icon: "clock" }
                ]}
              />
            </div>
          )}
        </div>
      </div>
  );
};

export default BetWinScreen;
