import React, { useEffect, useRef } from 'react';
import mainImage from '../assets/trumphead.png';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import useUserStore from "../store/userStore";

const FlashScreen = () => {
    const {user, loading, fetchUserOrCreate} = useUserStore();
    const telegram = window.Telegram.WebApp;
    telegram.ready();
    telegram.expand();

    const hasFetchedRef = useRef(false);

    useEffect(() => {
        if (!hasFetchedRef.current) {
            if (telegram.initData) {
                fetchUserOrCreate(telegram.initData);
            } else {
                console.error('Telegram Web App script not loaded');
            }
            hasFetchedRef.current = true;
        }
    }, [fetchUserOrCreate, telegram.initData]);

    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            if (!user.isVip) {
                const timer = setTimeout(() => {
                    navigate(user.isGreeted ? '/subscribe' : '/congratulations');
                    // setLoading(false);
                }, 1000);
                // Cleanup the timeout if the component unmounts before the timeout completes
                return () => clearTimeout(timer);
            } else {
                const timer = setTimeout(() => {
                    if (user.team === "blue" || user.team === "red") {
                        navigate('/tabs/game');
                    } else {
                        navigate('/select-party');
                    }
                }, 1000);
                // Cleanup the timeout if the component unmounts before the timeout completes
                return () => clearTimeout(timer);
            }
        }
    }, [navigate, user]);

    return (
        <div className="container">
            <h1 className="title">WHACK’EM</h1>
            <h2 className="subtitle">WHACK-A-MOLE</h2>
            <div className="image-container">
                <img src={mainImage} alt="Trump" className="main-image" />
            </div>
            <Footer />
            {loading && (
                <div className="loader-container">
                    <div className="custom-spinner"></div>
                    <p>Loading</p>
                </div>
            )}
        </div>
    );
};

export default FlashScreen;
