import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {formatNumberWithCommas, tweet} from "../helpers";
import ButtonLarge from "./ButtonLarge";
import {getExtraInformation, getTwitterVote} from "../services";
import axios from "axios";
import {API_URL} from "../constants";

const TWEET_POINTS = 25000;

const VoteTweet = () => {
  const [isUserSharedOnX, setIsUserSharedOnX] = useState(null);
  const navigate = useNavigate();
  const initData = window.Telegram?.WebApp?.initData;
  const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
  const gameUrl = process.env.REACT_APP_GAME_URL;

  useEffect(() => {
    (async () => {
      const extraInformationData = await getExtraInformation();
      setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
    })();
  }, []);

  const handleTweet = async () => {
    tweet(gameUrl, userId, navigate);
    await getTwitterVote();
    const extraInformationData = await getExtraInformation();
    setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
  };

  const handleClaimClick = async (id, platform) => {
    try {
      const response = await axios.request({
        method: 'POST',
        baseURL: API_URL,
        url: 'telegram-users/generate-social-media-reward',
        data: { platform: 'x-vote' },
        headers: {
          initdata: initData,
          'ngrok-skip-browser-warning': '69420'
        },
      });
      if (response.data.success) {
        const extraInformationData = await getExtraInformation();
        setIsUserSharedOnX(extraInformationData.isUserSharedOnX);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  return (
    <div className="vote-tweet earn-points-item">
      <div className="earn-points-bar">
        <img src="../images/x-vote-icon.png" alt="icon"/>
      </div>
      <div className="earn-points-content">
        <h2>EARN MORE AND HELP<br/> GROW THE GRAND PRIZE</h2>
        <h3 className="earn-points-title">TWEET AND EARN</h3>
        <span className="earn-points-value" data-content={`+${formatNumberWithCommas(TWEET_POINTS)} Pts`}>
          +{formatNumberWithCommas(TWEET_POINTS)} Pts
        </span>
        {isUserSharedOnX === 'claim' || isUserSharedOnX === 'done' ? (
          <ButtonLarge
            onClick={handleClaimClick}
            additionalClass={isUserSharedOnX === "done" ? "button-done" : ""}
            disabled={isUserSharedOnX === "null"}
          >
            {isUserSharedOnX === 'claim' ? 'Claim' : 'Done'}
          </ButtonLarge>
        ) : (
          <ButtonLarge
            onClick={handleTweet}
            additionalClass={isUserSharedOnX === 'pending' ? 'button-pending' : ''}
          >
            {isUserSharedOnX === 'pending' ? 'Pending' : 'Tweet'}
          </ButtonLarge>
        )}
        <p>Each Vote will increase the Grand Prize & your Winning Share!</p>
      </div>
    </div>
  );
};

export default VoteTweet;
