import React from "react";

const Preloader = () => (
  <div className="loader-container">
    <div className="custom-spinner"></div>
    <p>Loading</p>
  </div>
);

export default Preloader;
