import React from "react";
import closeIcon from "../assets/close.png";

const VoteModal = ({isOpen, onClose, children}) => (
  <>
    {isOpen && (
      <div className="modal vote-modal">
        <div className="modal-wrapper">
          <div className="modal-content">
            {children}
            <button className="vote-modal-close" onClick={onClose}>
              <img src={closeIcon} alt="close"/>
            </button>
          </div>
        </div>
      </div>
    )}
  </>
);

export default VoteModal;
