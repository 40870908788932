import sprite from "../assets/sprite.svg";

const WalletScreen = () => {
  return (
    <div className="coming-soon">
      <div className="coming-soon-icon">
        <svg width="30" height="30">
          <use href={`${sprite}#wallet`}></use>
        </svg>
      </div>
      <h1>Wallet Coming Soon</h1>
      <p>The wallet feature is currently in development<br/> and will be launched soon.</p>
    </div>
  );
};

export default WalletScreen;
