import React, {useEffect, useState} from 'react';
import axios from "axios";
import {API_URL, twitterLink} from "../constants";
import {TbCopy} from "react-icons/tb";
import {IoAlertCircleOutline} from "react-icons/io5";

import coinImage from '../assets/goldcoin.png';

const INITDATA_HEADER = { 'ngrok-skip-browser-warning': '69420' };
const LEVELS = [
    { level: 1, minReferrals: 0, maxReferrals: 1, displayInterval: true },   // Registration/Join TG Channel
    { level: 2, minReferrals: 1, maxReferrals: 5, displayInterval: true },   // Refer 1-5 Friends
    { level: 3, minReferrals: 5, maxReferrals: 10, displayInterval: true },  // Refer 6-10 Friends
    { level: 4, minReferrals: 10, maxReferrals: 15, displayInterval: true }, // Refer 11-15 Friends
    { level: 5, minReferrals: 15, maxReferrals: 20, displayInterval: true }, // Refer 16-20 Friends
    { level: 6, minReferrals: 20, maxReferrals: 25, displayInterval: true }, // Refer 21-25 Friends
    { level: 7, minReferrals: 25, maxReferrals: 30, displayInterval: true }, // Refer 25-30 Friends
    { level: 8, minReferrals: 30, maxReferrals: 50, displayInterval: true }, // Refer 31-50 Friends
    { level: 9, minReferrals: 50, maxReferrals: 100, displayInterval: true },// Refer 51-100 Friends
    { level: 10, minReferrals: 100, maxReferrals: Infinity, displayInterval: false } // Refer 101+ Friends
];
const DISPLAY_REWARDS_PER_PAGE = 5;

function getLevelByAmountReferrals(amountReferrals) {
    if (amountReferrals === 0) return LEVELS[0];
    return LEVELS.find(level => amountReferrals < level.maxReferrals
    ) || { level: 0, minReferrals: 0, maxReferrals: 0 };
}

const PointsHeader = ({ balance, amountReferrals }) => {
    const { level, minReferrals, maxReferrals, displayInterval } = getLevelByAmountReferrals(amountReferrals);
    const interval = maxReferrals - minReferrals;
    const referenceInInterval = amountReferrals - minReferrals;
    const progress = referenceInInterval/interval*100;

    return (<>
        <div className="points-header">
            <div className="points-left">
                <div className="points-title">MY POINTS:</div>
                <div className="points-level">Level {level}</div>
            </div>
            <div className="points-right">
                <div className="points-value">
                    <img src={coinImage} alt="Coin" className="points-coin"/>
                    {balance.toLocaleString()}
                </div>
                {displayInterval ? <div className="points-progress">{referenceInInterval}/{interval}</div> : ''}
            </div>
        </div>
        {
            displayInterval
                ? <div className="progress-bar">
                    <div className="progress" style={{width: `${progress}%`}}/>
                </div>
                : ''
        }
    </>);
};

const InviteFriends = () => {
    // Make sure to have REACT_APP_GAME_URL in your .env file
    const gameUrl = process.env.REACT_APP_GAME_URL;
    const userId = window.Telegram.WebApp.initDataUnsafe?.user?.id;

    // Construct the URL
    const encodedText = encodeURIComponent("Let's play Whack'Em game together!");
    const fullUrl = `${gameUrl}?startapp=ref_${userId}`;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(fullUrl).then(() => {
            //   alert('URL copied to clipboard');
        }).catch(err => {
          console.error('Failed to copy: ', err);
        });
      };

    return (
        <div className='invite-friends-container'>
            <div className='content'>
                <h3>Invite Friends <IoAlertCircleOutline /></h3>
                <p>Increase Your Membership Level and Points</p>
                <a href={`https://t.me/share/url?url=${encodeURIComponent(fullUrl)}&text=${encodedText}`}>
                    <button className='orange-button'>Invite Friends</button>
                </a>
                <div className="input-container">
                    <input type="text" id="copyInput" value={fullUrl} readOnly />
                    <TbCopy onClick={copyToClipboard} style={{ cursor: 'pointer' }} />
                </div>
            </div>
        </div>
    );
};

const Reward = ({ index, username, firstName, lastName, type, amount }) => {
    const name = username
        ? `@${username}`
        : [firstName, lastName].filter(Boolean).join(' ');

    return (
        <tr key={index}>
            {type==='referral' &&
                <td className='username'>
                    <div className='username-container'>
                        {name && <>
                                <img className='round-avatar'
                                        src={"https://avatar.iran.liara.run/username?username=" + firstName + "+" + lastName + ""}
                                        alt='User'/>
                                {name}
                            </>
                        }
                    </div>
                </td>
            }
            <td className={type!=='referral' ? 'points-type with-padding' : 'points-type'} colSpan={type!=='referral' ? 2 : 1}>{type} points</td>
            <td className='points-count'>+{amount.toLocaleString()}</td>
        </tr>
    );
};

const ReferralScreen = () => {
    const [rewards, setRewards] = useState([]);
    const [balance, setBalance] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [isUserSubscribedOnX, setIsUserSubscribedOnX] = useState(false);
    const [amountReferrals, setAmountReferrals] = useState(0);
    const initData = window.Telegram?.WebApp?.initData;
    
    useEffect(() => {
        getExtraInformation(initData);
    }, [initData]);

    const getRewards = async (initData, page) => {
        try {
            const res = await axios.request({
                baseURL: API_URL,
                url: 'telegram-users/rewards',
                headers: { initdata: initData, ...INITDATA_HEADER },
                params: { page, take: DISPLAY_REWARDS_PER_PAGE }
            });
            setRewards(res.data.data);
            setPageCount(res.data.meta.pageCount);
        } catch (e) {
            console.error("Error fetching rewards data:", e);
        }
    };

    useEffect(() => {
        getRewards(initData, currentPage);
      }, [initData, currentPage]);

    const handleDotClick = (page) => {
        setCurrentPage(page);
    };

    const getExtraInformation = async (initData) => {
        try {
            const response = await axios.request({
                baseURL: API_URL,
                url: 'telegram-users/extra-information',
                headers: { initdata: initData, ...INITDATA_HEADER }
            }).then(res => res.data);
            setBalance(response.balance);
            setIsUserSubscribedOnX(response.isUserSubscribedOnX);
            setAmountReferrals(response.amountReferrals);
        } catch (e) {
            console.error("Error fetching balance data:", e);
        }
    };

    const generateTwitterSubscriptionReward = async () => {
        try {
            await axios.request({
                baseURL: API_URL,
                url: 'telegram-users/generate-twitter-reward',
                headers: { initdata: initData, ...INITDATA_HEADER }
            });
        } catch (e) {
            console.error("Error fetching balance data:", e);
        }
    };

    return (
        <div className="container">
            <div className="points-container">
                <PointsHeader balance={balance} amountReferrals={amountReferrals}  />
            </div>
            <InviteFriends />
            {
                !isUserSubscribedOnX && <div className='invite-friends-container'>
                    <div className='content'>
                        <h3>Follow Us</h3>
                        <p>Follow our Twitter and Earn 1,000 Points</p>
                        <a target='_blank' href={twitterLink} rel="noreferrer">
                            <button className='orange-button marging-bottom'
                                    onClick={generateTwitterSubscriptionReward}>Follow X
                            </button>
                        </a>
                    </div>
                </div>
            }
            <div className='point-history-container'>
                <table>
                    <thead>
                    <tr>
                        <td colSpan={3}>My Point History</td>
                    </tr>
                    </thead>
                    <tbody>
                    {rewards.map(({username, firstName, lastName, type, amount}, index) =>
                        <Reward index={index} username={username} firstName={firstName} lastName={lastName} type={type} amount={amount}/>)
                        }
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colSpan={3}>
                            <div className="dots-pagination-container">
                                {[...Array(pageCount)].map((_, index) => (
                                <div
                                    key={index}
                                    className={`dot ${currentPage === index + 1 ? 'active' : ''}`}
                                    onClick={() => handleDotClick(index + 1)}
                                    style={{ cursor: 'pointer' }}
                                ></div>
                                ))}
                            </div>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

export default ReferralScreen;
