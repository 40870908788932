import {useEffect, useRef, useState} from "react";
import axios from "axios";
import useUserStore from "../store/userStore";
import {API_URL} from "../constants";
import {getGameStats} from "../services";
import UserItem from "../components/UserItem";
import Avatar from "../components/Avatar";
import Preloader from "../components/Preloader";
import starGold from "../assets/star-gold.png";
import starSilver from "../assets/star-silver.png";
import starBronze from "../assets/star-bronze.png";
import {formatNumberWithCommas, getDisplayName} from "../helpers";

const ScoresScreen = () => {
  const {user, loading, fetchUserOrCreate} = useUserStore();
  const [currentUserScore, setCurrentUserScore] = useState(0);
  const [currentUserRank, setCurrentUserRank] = useState(0);
  const [scores, setScores] = useState([]);
  const [limit, setLimit] = useState(3);
  const [page, setPage] = useState(1);
  const [isScoresLoading, setIsScoresLoading] = useState(false);
  const triggerRef = useRef(null);
  const initData = window.Telegram?.WebApp?.initData;
  const rankImages = {
    1: starGold,
    2: starSilver,
    3: starBronze
  };

  const fetchScores = async (limit, page) => {
    try {
      setIsScoresLoading(true);
      const response = await axios.request({
        method: 'GET',
        baseURL: API_URL,
        url: '/game/high-scores',
        headers: {
          initdata: initData,
          'ngrok-skip-browser-warning': '69420'
        },
      });
      setScores([...response.data.highScores]);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsScoresLoading(false);
    }
  }

  useEffect(() => {
    fetchScores();
  }, [page]);

  useEffect(() => {
    (async () => {
      if (initData) {
        await fetchUserOrCreate(initData);
      } else {
        console.error('Telegram Web App script not loaded');
      }

      const gameStatsData = await getGameStats();
      setCurrentUserScore(gameStatsData.myPoints);
      setCurrentUserRank(gameStatsData.rank);
    })();
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <div className="scores-inner">
      <div className="scores-main">
        <div className="my-info">
          <UserItem
            telegramPhotoUrl={user.telegramPhotoUrl}
            firstName={user.firstName}
            lastName={user.lastName}
            name={user.username}
            rank={currentUserRank}
            points={formatNumberWithCommas(currentUserScore)}
            team={user.team}
            mainUser={true}
            element="div"
          />
        </div>

        <ul className="top-users">
          {scores.slice(0, 3).map((topUser, index) => {
            const rankSrc = rankImages[index + 1] || null;
            return (
              (
                <li
                  key={topUser.id}
                  className={`top-user-item ${index + 1 === 1 ? 'first-place' : ''}`}
                  style={{
                    order: index + 1 === 1 ? 2 : index + 1 === 2 ? 1 : 3
                  }}
                >
                  <h3 className="user-title">
                    {getDisplayName(topUser.firstName, topUser.lastName, topUser.username)}
                  </h3>
                  <div className="user-points">
                <span data-content={`${formatNumberWithCommas(topUser.totalBalance)}Pts`}>
                  {formatNumberWithCommas(topUser.totalBalance)}Pts
                </span>
                  </div>
                  <div className="avatar-wrap">
                    <Avatar
                      telegramPhotoUrl={topUser.telegramPhotoUrl}
                      firstName={topUser.firstName}
                      lastName={topUser.lastName}
                      size={index + 1 === 1 ? 87 : 70}
                      team={topUser.team}
                    />
                    <div className="user-rank">
                      <img src={rankSrc} alt={`Rank ${index + 1}`}/>
                      <span>{index + 1}</span>
                    </div>
                  </div>
                </li>
              )
            )
          })}
        </ul>
      </div>

      <ul className="users-list">
        {scores.slice(3).map((user, index) => (
          <UserItem
            key={user.username}
            telegramPhotoUrl={user.telegramPhotoUrl}
            firstName={user.firstName}
            lastName={user.lastName}
            name={user.username}
            rank={index + 4}
            points={formatNumberWithCommas(user.totalBalance)}
            team={user.team}
          />
        ))}
        {isScoresLoading ? <Preloader/> : null}
        <div className="trigger" ref={triggerRef}></div>
      </ul>
    </div>
  );
};

export default ScoresScreen;
