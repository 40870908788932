import React from "react";
import sprite from "../assets/sprite.svg";
import {formatValue} from "../helpers";

const Statistics = ({ stats }) => {
  const renderStatItem = ({ title, value, icon }) => {
    const formattedValue = formatValue(title, value);

    return (
      <li key={title}>
        <div className="account-stats-item-inner">
          <div className="account-stats-left">
            {icon && (
              <svg height="20" width="20">
                <use href={`${sprite}#${icon}`}></use>
              </svg>
            )}
          </div>
          <div className="account-stats-right">
            <h3>{title}</h3>
            <span>{formattedValue}</span>
          </div>
        </div>
      </li>
    )
  };

  return (
    <div className="account-stats">
      <div className="account-stats-title">
        <h2>Statistics</h2>
      </div>
      <ul className="account-stats-list">
        {stats.map(renderStatItem)}
      </ul>
    </div>
  );
};

export default Statistics;
