import axios from "axios";
import {API_URL} from "../constants";

const initData = window.Telegram?.WebApp?.initData;

export const getGameStats = async () => {
  try {
    const {data} = await axios.request({
      method: 'GET',
      baseURL: API_URL,
      url: '/game/stats',
      headers: {
        initdata: initData,
        'ngrok-skip-browser-warning': '69420'
      },
    });
    return data;
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
}

export const getExtraInformation = async () => {
  try {
    const {data} = await axios.request({
      baseURL: API_URL,
      url: 'telegram-users/extra-information',
      headers: {
        initdata: initData,
        'ngrok-skip-browser-warning': '69420'
      }
    });
    return data;
  } catch (e) {
    console.error("Error fetching balance data:", e);
  }
};

export const getVoteStats = async () => {
  try {
    const {data} = await axios.request({
      method: 'GET',
      baseURL: API_URL,
      url: '/vote/stats',
      headers: {
        initdata: initData,
        'ngrok-skip-browser-warning': '69420'
      },
    });
    return data;
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
}

export const postVoteCats = async (team) => {
  try {
    const {data} = await axios.request({
      method: 'POST',
      baseURL: API_URL,
      url: '/vote/cast',
      headers: {
        initdata: initData,
        'ngrok-skip-browser-warning': '69420'
      },
      data: { candidate: team },
    });
    return data;
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
}

export const getTwitterVote = async () => {
  try {
    await axios.request({
      baseURL: API_URL,
      url: 'telegram-users/x-vote',
      headers: {
        initdata: initData,
        'ngrok-skip-browser-warning': '69420'
      },
    });
  } catch (e) {
    console.error("Error fetching balance data:", e);
  }
}
