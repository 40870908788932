import ButtonLarge from "./ButtonLarge";


const Modal = ({isOpen, onPlayAgain, onViewPoints, children}) => (
  <>
    {isOpen && (
      <div className="modal">
        <div className="modal-wrapper">
          <div className="modal-content">
            {children}
            <ButtonLarge onClick={onPlayAgain}>Play Again!</ButtonLarge>
            <ButtonLarge additionalClass="view-points" onClick={onViewPoints}>View My Points</ButtonLarge>
          </div>
        </div>
      </div>
    )}
  </>
);

export default Modal;
