import {create} from 'zustand';
import axios from 'axios';
import {API_URL} from "../constants";

const useUserStore = create((set) => ({
  user: null,
  loading: true,
  setUser: (user) => set({ user }),
  setLoading: (loading) => set({ loading }),
  fetchUserOrCreate: async (initDate) => {
    set({ loading: true });
    try {
      const response = await axios.request({
        method: 'GET',
        baseURL: API_URL,
        url: '/telegram-users/find-or-create',
        headers: {
          initdata: initDate,
          'ngrok-skip-browser-warning': '69420',
        },
      });

      set({ user: response.data });
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      set({ loading: false });
    }
  },
}));

export default useUserStore;
