import React, {useEffect, useState} from "react";
import useUserStore from "../store/userStore";
import {getExtraInformation, getGameStats} from "../services";
import {formatNumberWithCommas, formatPercentage} from "../helpers";
import Avatar from "../components/Avatar";
import Statistics from "../components/Statistics";
import PointHistory from "../components/PointHistory";
import Preloader from "../components/Preloader";
import coinImage from "../assets/goldcoin.png";

const AccountScreen = () => {
  const {user, loading, fetchUserOrCreate} = useUserStore();
  const [totalGamePointsIssued, setTotalGamePointsIssued] = useState(0);
  const [myPoints, setMyPoints] = useState(0);
  const [airdropAllocation, setAirdropAllocation] = useState(0);
  const [highScore, setHighScore] = useState(0);
  const [ranking, setRanking] = useState(0);
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [playingTime, setPlayingTime] = useState(0);
  const initData = window.Telegram?.WebApp?.initData;

  useEffect(() => {
    (async () => {
      if (initData) {
        await fetchUserOrCreate(initData);
      } else {
        console.error('Telegram Web App script not loaded');
      }

      const extraInformationData = await getExtraInformation();
      setTotalGamePointsIssued(extraInformationData.totalGamePointsIssued);
      setMyPoints(extraInformationData.myPoints);
      setAirdropAllocation(extraInformationData.myAirdropAllocation);

      const gameStatsData = await getGameStats();
      setHighScore(gameStatsData.highScore);
      setRanking(gameStatsData.rank);
      setGamesPlayed(gameStatsData.gamesPlayed);
      setPlayingTime(gameStatsData.playingTime);
    })();
  }, [fetchUserOrCreate, initData]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <div className="account-container">
      <div className="account-inner">
        <div className="account-user">
          <Avatar
            telegramPhotoUrl={user.telegramPhotoUrl}
            firstName={user.firstName}
            lastName={user.lastName}
            team={user.team}
            size={50} />
          <span>@{user.username}</span>
        </div>

        <ul className="account-info">
          <li>
            <h3>My Points:</h3>
            <span>
              <img src={coinImage} alt="Coin" width="30" height="26"/>
              {formatNumberWithCommas(totalGamePointsIssued)}
            </span>
          </li>
          <li>
            <h3>Total game points issued:</h3>
            <span>{formatNumberWithCommas(myPoints)}</span>
          </li>
          <li>
            <h3>My airdrop allocation*:</h3>
            <span>{formatPercentage(airdropAllocation)}</span>
          </li>
        </ul>
        <p className="account-footnote">
          *Boast your Airdrop Allocation by Playing and Earning More Points
        </p>
        <Statistics
          stats={[
            { title: "High Score", value: formatNumberWithCommas(highScore), icon: "award" },
            { title: "Ranking", value: `#${ranking}`, icon: "trend-up" },
            { title: "Games Played", value: formatNumberWithCommas(gamesPlayed), icon: "play" },
            { title: "Playing Time", value: formatNumberWithCommas(playingTime), icon: "clock" }
          ]}
        />
        <PointHistory />
      </div>
    </div>
  );
};

export default AccountScreen;
