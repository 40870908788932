import sprite from "../assets/sprite.svg";

const ConvertSwapScreen = () => {
  return (
    <div className="coming-soon">
      <div className="coming-soon-icon">
        <svg width="34" height="34">
          <use href={`${sprite}#convert-swap`}></use>
        </svg>
      </div>
      <h1>Convert & Swap Coming Soon</h1>
      <p>The convert/swap feature is currently in development and will be launched soon.</p>
    </div>
  );
};

export default ConvertSwapScreen;
